// 获取公司授信信息
import consumer_credit_queryCreditApply from '@/lib/data-service/haolv-default/consumer_credit_queryCreditApply.js';

export default {
    data () {
        return {
            loading: false,
            detail: {},
        }
    },
    methods: {
        init() {
            const __this = this;
            __this.req_detail();
        },
        req_detail () {
            const __this = this;
            consumer_credit_queryCreditApply().then((res) => {
                __this.detail = res.datas;
            });
        },
        downAccessory(url) { // 点击附件下载
            const __this = this;
            const eleLink = document.createElement('a');
            eleLink.href = url;
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },
    },
    activated () {
        const __this = this;
        __this.init();
    },
    watch: {

    },
    filters: {
        get_url_name(val) {
            if (!val) return val;
            let str = '';
            str = val.substring(val.lastIndexOf("/") + 1);
            return str;
        },
        format_scale(val) {
            if (val === 1) {
                return '0~20人'
            } else if (val === 2) {
                return '20~50人'
            } else if (val === 3) {
                return '50~100人'
            } else if (val === 4) {
                return '100~300人'
            } else if (val === 5) {
                return '300~1000人'
            } else if (val === 6) {
                return '1000人以上'
            } else {
                return ''
            }
        },
        format_carryRules(val) {
            switch (Number(val)) {
                case 0:
                    return "进位到元";
                    break;
                case 1:
                    return "进位到分";
                    break;
            }
        }
    }
}